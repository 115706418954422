@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,700,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*
1. Estilo General
---------------

2. INPUT TEXT
---------------

2. INPUT PASSWORD
---------------

---------------
4. Estilo Fuente
---------------
    4.1. Poppins
    4.2. Roboto
5. Tamaño Fuente
---------------
*/
@charset "utf-8";

/***************************************************
					1. Estilo General
***************************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	border: 0;
	margin: 0;
	font-size: 100%;
	font: inherit;
	padding: 0;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	background: #000; /*background por defecto body */
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size:18px;
	line-height: 1;
}
ol, ul {
	list-style: none;
}
input[type="submit"]{
	-webkit-appearance: none;
	}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img{
	border:none
	}
/*div#cuerpo, div.footer, */
div.contenido{
	/*width:1200px;*/
	margin:0 auto;
	width:1200px;
	}
.contenido.max_360{
	max-width:360px;
}
a{
	text-decoration:none
	}
p{
	color: #fff;
}
.clr{
	clear:both
	}
@media (max-width: 1200px) {
	/*div#cuerpo, div.footer, */

	div.contenido{
		width:100%;
	}
}
@media (max-width: 767px) {
	body {
		font-size:14px;
	}
}

@media (max-width: 1199px){
	/* .form{
		margin: 50px 5%;
	} */
}
header{
	/* height: 50px; */
	  background: #13161d;
	  color: #ffffff;
}
/***************************************************
					1. HEADER
***************************************************/

.iconUsu{
	border-radius: 50%;
}
.title_header{
	text-align: center;
	width: 100%;
}
	.logo{
		height: 35px;
		width: auto;
	}
	.text_logo{
		display: inline;
		padding: 12px;
		/* width: auto; */
	}
/***************************************************
					1. Titulos
***************************************************/
h2{
	color: #ffffff;
	line-height: 20px;
	letter-spacing: 1.285714px;
	margin: 5px auto;
	text-align: center;
}
h3{
	color: #cccccc;
	line-height: 20px;
	letter-spacing: 0.5px;
}
#title_section .MuiSvgIcon-root{
	margin: -3px 0;
}
#title_section h3{
	margin-left: 10px;
}
/***************************************************
					1. CONTENIDO
***************************************************/
.mainpage{
	background: #1E232B;
	/* height: 100vh; */
	padding-top: 30px;
	padding-bottom: 60px;
	height: 100%;
	min-height: 96vh;
}
.form{
	background: #1E232B;
	box-sizing: border-box;
	/* margin: 50px auto; */
	margin: 0 auto;
	max-width: 360px;
	min-height: 100vh;
	padding: 24px 20px;
}
.section_form{
	position: relative;
	background: inherit;
}
.nav{
	background: #788ba5;
}
.inline{
	display: inline-block;
	margin: -2px 2px;
}
/**Input**/
/* .MuiOutlinedInput-input{
	border: solid 1px #3d4755;
	background: #13161d !important;
	color: #ffffff !important;
} */
.form .MuiFormControl-root{
	margin: 10px 0;
	width: 100%;
}
.form .MuiInputBase-input, .input_dark{
	background: #13161d;
	color: #ffffff;
	width: 100%;
}
.form .MuiInputLabel-outlined,.form .MuiInputLabel-outlined.Mui-focused, .input_dark label.Mui-focused, .input_dark fieldset.Mui-focused{
	color: #cccccc;
}
.form .MuiOutlinedInput-notchedOutline, .form .MuiOutlinedInput-notchedOutline .Mui-focused, .form .MuiOutlinedInput-notchedOutline:hover, .input_dark.MuiOutlinedInput-notchedOutline:hover {
	border-color: #3d4755;
}
/* .form .MuiOutlinedInput-root.Mui-focused{
	border-color: rgba(255, 255, 255, 0.23);
} */
/**Select**/
.form  .MuiSelect-icon {
	color: #3d4755;
}
/**Data Picker**/
.coloricon, .coloricon .MuiSvgIcon-root{
	color: #3d4755;
}
.Mui-disabled{
	border-color: #3d4755;
}
.form .nobackground, .nobackground{
	background-color: transparent;
	border-bottom: 1px solid #5b6a7f;
}
/**Tab**/
.OpTab .MuiBox-root{
	padding: 0;
}
.tabsfull .MuiTab-textColorInherit{
	width: 50%;
}
/**Card**/
.list_card .MuiCardHeader-action{
	border-left: solid 1px #3d4755;
	height: 110px;
}
.list_card .MuiCardHeader-action button.MuiButtonBase-root{
	margin:30px 0;
}
.list_card span{
	vertical-align: middle;
	margin-left: 5px;
}
.list_card .MuiSvgIcon-root, .sbtitulo{
	color: #5b6a7f;

}
.list_card .MuiSvgIcon-root{
	width: 20px;
	vertical-align: middle;
}
.bgazul{
	/* background-color: #1338be; */
	width:auto;
	/* width: 12px;
	height: 12px; */
	border-radius: 50%;
	display: inline-block;
	vertical-align: super;
}
.list_card .bgazul .MuiSvgIcon-root{
	color: #1338be;
}
.bgverde{
	/* background-color: #32cd32; */
	border-radius: 50%;
	width:auto;
	/* width: 12px;
	height: 12px; */
	display: inline-block;
	vertical-align: super;
}
.list_card .bgverde .MuiSvgIcon-root{
	color: #32cd32;
	;
}
.bgrojo{
	/* background-color: #f04d43; */
	border-radius: 50%;
	width:auto;
	/* width: 12px;
	height: 12px; */
	display: inline-block;
	vertical-align: super;
}
.list_card .bgrojo .MuiSvgIcon-root{
	color: #f04d43;
	;
}
body .LinkItem{
	color: inherit;
}
body .LinkItem.verlogs{
	margin: 30px 0;
	position: relative;
	display: block;
	font-weight: 500;
}
/***************************************************
					2. INPUT TEXT
***************************************************/

input{
	background: #13161d;
	border: solid 1px #3d4755;
	border-radius: 10px;
	box-sizing: border-box;
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	height: 40px;
	letter-spacing: 0.5px;
	line-height: 17px;
	margin: 5px auto;
	padding: 0 10px;
	width: 100%;
}
.label_input{
	line-height: 12px;
	margin: 6px 0;
}
.margininput{
	margin: 9px 0;
}
/* .form .MuiGrid-item{
	padding-bottom: 0px !important;
	padding-top: 0px !important;
} */
.cont-1{
	width: 100%;
}
.cont-2{
	width: 50%;
}
.cont-3{
	width: 34%;
}

/***************************************************
					3. BUTTON
***************************************************/
.boton{
	border-radius: 10px;
	cursor: pointer;
	height: 40px;
	letter-spacing: 0.5px;
	line-height: 15px;
	margin: 5px auto;
	text-align: center;
	width: 100%;
}
.full_content{
	width: 100%;
	text-transform: capitalize;
}
.MuiButtonBase-root.margin_top, .margin_top{
	margin-top: 10px;
}
/* .btn_general{
	background: #788ba5;
	color: #ffffff;
	border: solid 1px #788ba5;
}
.btn_secundario{
	background: #ffffff;
	border: solid 1px #788ba5;
	color: #5b6a7f;
} */

/**Calendar**/
.calendar .MuiIconButton-root {
	padding: 12px 0;
}
.calendar .MuiInputBase-input{
	font-size: 14px;
}
/**button more**/
.btn_more{
	display: block;
	text-align: center;
}
/***************************************************
					4. SELECT
***************************************************/
select{
	background: #13161d;
	border: solid 1px #3d4755;
	border-radius: 10px;
	color: #ffffff;
	height: 40px;
	line-height: 17px;
	letter-spacing: 0.5px;
	width: 100%;
}
/* .text_select, .MuiList-root .text_select{
	color: #ffffff;
} */
/* .marginselect{
	margin: 14px 0;
}
.selectui{
	background: #13161d;
	border: solid 1px #3d4755;
	border-radius: 10px !important;
	color: #ffffff !important;
	height: 40px;
	line-height: 17px;
	letter-spacing: 0.5px;
	width: 100%;
}
.MuiMenu-list, .MuiMenu-list{
	color: #ffffff !important;
	background: #13161d;
	/* border: solid 1px #3d4755; /
}
.MuiMenu-list li{
	border-bottom: solid 1px #3d4755;
}
.MuiMenu-list li:hover{
	background: #1e232b;
}
.MuiSelect-icon{
	color: #ffffff !important ;
} */

/***************************************************
					5. TEXTAREA
***************************************************/
textarea{
	background: #13161d;
	border: solid 1px #3d4755;
	box-sizing: border-box;
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	/* height: 81px; */
	letter-spacing: 0.5px;
	line-height: 17px;
	margin: 5px auto;
	padding: 8px 12px;
	width: 100%;
}
/***Timeline***/
.line_time{
	background: rgb(160, 178, 184) none repeat scroll 0% 0%;
	height: 65%;
	left: 16px;
	position: absolute;
	top: 40px;
	width: 2px;
}
.cont_timeline{
	font-size: 80%;
	font-weight: 300;
	margin: 0px auto;
	padding: 10px 0px;
	position: relative;
	width: 100%;
}
	.contItem{
		/* margin: 10px 0px; */
		padding-left: 40px;
		position: relative;
		text-align: left;
		/* height: 60px; */

	}
		.divider{
			border-bottom: 1px solid #788ba5;
			bottom: 0;
			height: 1px;
			left: 20px;
			position: absolute;
			width: 95%;
			z-index: 0;
		}
		.iconItem{
			background: #13161d none repeat scroll 0% 0%;
			border-radius: 50%;
			display: -webkit-flex;
			display: flex;
			/* top: 20px; */
			height: 20px;
			left: 6px;
			margin-left: 1px;
			margin-top: 6%;
			position: absolute;
			width: 20px;
			z-index: 1;
		}
			.icon_green{
				border: 2px solid #36af47;
			}
			.icon_red{
				border: 2px solid #e02020;
			}
			.icon_gris{
				border: 2px solid #788ba5;
			}
		.datos{
			position: relative;
			text-align: left;
			/* padding: 25px 0; */
			padding: 3% 0;
		}
		/* .datos .dateItem{

		} */
		.datos .descItem span{
			display: block;
			margin: 0;
		}
		.contItem:last-child .line_time, .contItem:last-child .divider{
			display: none;
		}
	/**Syle 2 date icon**/
		.contItem{
			padding-left: 50px;
		}
		.iconItemData{
			background: #13161d none repeat scroll 0% 0%;
			border-radius: 50%;
			display: -webkit-flex;
			display: flex;
			/* top: 20px; */
			width: 40px;
  			height: 40px;
			left: 0;
			/* margin-left: 1px; */
			margin-top: 6%;
			position: absolute;
			z-index: 1;
		}
			.iconItemData p{
				width: 40px;
				text-align: center;
				padding: 2px 0;
				letter-spacing: 0.7px;
			}
			.iconItemData.icon_gris p{
				color:#788ba5;
			}

		.DateIcon .datos{
			position: relative;
			text-align: left;
			/* padding: 25px 0; */
			padding: 10% 0;
		}
/* Home */
/* body .item_card {

} */
body .btn_rigth{
	float: right !important;
}
.icon_form{
	vertical-align: sub;
}
/**/
/********List Item**********/
.alignv_mid{
	vertical-align:middle;
}
.alignv_sub{
	vertical-align: sub;
}
/**/
.MuiSpeedDialAction-staticTooltipLabel, .optionspeed{
	min-width: -webkit-max-content !important;
	min-width: max-content !important;
}
/***************************************************
                    6. Estilo Fuente
                        4.1. Montserrat
***************************************************/
/*Fuentes100;200;300;400;500;600;700;800;900*/
body .fnt_black{
    font-weight: 900;
}
body .fnt_extrabold{
    font-weight: 800;
}
body .fnt_bold{
    font-weight: 700;
}
body .fnt_semibold{
    font-weight: 600;
}
body .fnt_medium{
    font-weight: 500;
}
body .fnt_regular{
    font-weight: 400;
}
body .fnt_light{
    font-weight: 300;
}
body .fnt_extralight{
    font-weight: 200;
}
body .fnt_thin{
    font-weight: 100;
}
/***************************************************
					3. Separador
***************************************************/
.separador {
	border: solid 1px #979797;
	box-sizing: border-box;
	display: block;
	/* height: 1px; */
	margin:8px 20px;
	position: relative;
  }
/***************************************************
					5. Tamaño Fuente
***************************************************/
.fnt_18{
	font-size: 18px;
}
.fnt_14, .fnt_14 span{
	font-size: 14px;
}
.list_card .fnt_14 span{
	font-size: 12px;
}
.fnt_12{
	font-size: 12px;
}
.fnt_10{
	font-size: 10px;
}
/*---------------------*/


.fnt_38{
	font-size: 38px;
}
.fnt_25_1{
	font-size: 25.01px;
}
.fnt_25{
	font-size: 25px;
}
.fnt_24{
	font-size: 24px;
}

.fnt_17_9{
	font-size: 17.99px;
}
.fnt_17{
	font-size: 17px;
}
.fnt_16{
	font-size: 16px;
}
.fnt_16_1{
	font-size: 16.01px;
}
/*custom sweetalert2*/
body .bg_dark{
	background: #1e232b;
	color: #fff;
	border: 1px solid #788ba5;
}
body .btn_success{
	background-color: rgb(189, 173, 144) !important;
}
body .btn_deny{
	background-color: #788ba5 !important;
}
body .btn_cancel{
	border: 1px solid #788ba5 !important;
	background-color: #1e232b !important;
}
body .text_white{
	color: #fff !important;
}
body .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px #788ba5;
}

.link_a{
    color: white;
}

.border_input{
	box-sizing: border-box;
    border-radius: 4px;
    height: -webkit-max-content;
    height: max-content;
    background-color: #656565;
    font-size: 18px;
    color: white;
    border: none;

}

.cont_icon{
	height: 56px;
    width: 50px;
    border-radius: 4px;
	background-color: #333333;
    float: left;
    position: relative;
}

.botom_login{
	width: 100%;
}

.texto_login{
	display: block;
    margin: 3px auto;
    font-weight: 600;
    font-size: 15px;
}
.aling_text{
    text-align: right !important;
    width: 260px;	
}
.logo_login{
    margin-top: 50px;
    margin-bottom: 50px;
}
.form_login{
    margin-top: 50px;
}
.titulo_recuperar{
    color: #9F8E6E;
    text-align: center;
    font-weight: bold;
}
.contenido_popup{
    color: #fff !important;
    text-align: center;
}
.boton_cancelar{
    color: #9F8E6E !important;
    border: 2px solid #9F8E6E !important;
}
.boton_enviar{
    color: #fff !important;
    background-color: #9F8E6E !important;
}
.input_dark {
    background: #13161d;
    color: #ffffff;
}


*,
*:after,
*:before {
  box-sizing: border-box;
}
/* html {
  height: 100%;
}
body {
  height: 100%;
  background: #444;
  color: #fffce1;
  font-family: 'Raleway', Arial, sans-serif;
}
#app {
  height: 100%;
}
a {
  color: #4e4a46;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #c94e50;
}
main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}
h1 {
  font-weight: 800;
  font-size: 3.75em;
} */
/* .description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
} */
/* .demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;
}
.demo-buttons a {
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15em;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
}
.demo-buttons a.current-demo {
  background: #c94e50;
  color: #fffce1;
} */
.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}
.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.side-button.active {
  background: #c94e50;
  color: #fffce1;
}
@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
  left: 20px;
  top: 16px;
}
.right .bm-burger-button {
  left: auto;
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background: #ffffff;
}
.bm-morph-shape {
  fill: #13161d;
}
.bm-menu {
  background: #3d4755;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}
.bm-item:focus {
  outline: none;
}
.menu-1 .bm-cross {
  background: #bdc3c7;
}
.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.menu-2 .bm-cross {
  background: #999;
}
.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}
.menu-2 a {
  padding: 1em;
}
.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #94959e;
}
.menu-3 .bm-cross {
  background: #888;
}
.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
.menu-3 i {
  opacity: 0.5;
}
.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
.menu-4 .bm-cross {
  background: #888;
}
.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 h2 i {
  margin-left: 0.2em;
}
.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}
.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}
.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}
#slide .bm-cross,
#stack .bm-cross,
#reveal .bm-cross {
  background: #bdc3c7;
}
#slide .bm-menu,
#stack .bm-menu,
#reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
#bubble .bm-cross,
#push .bm-cross {
  background: #999;
}
#bubble .bm-menu,
#push .bm-menu {
  padding: 3.4em 1em 0;
}
#bubble a,
#push a {
  padding: 1em;
}
#bubble i,
#push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}
#elastic .bm-cross {
  background: #888;
}
#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}
#elastic i {
  opacity: 0.5;
}
#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}
#scaleDown .bm-cross,
#scaleRotate .bm-cross,
#pushRotate .bm-cross,
#fallDown .bm-cross {
  background: #888;
}
#scaleDown h2,
#scaleRotate h2,
#pushRotate h2,
#fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  /* color: rgba(0, 0, 0, 0.4); */
  color: rgba(120, 139, 165, 0.5);
  box-shadow: inset 0 -1px rgba(120, 139, 165, 0.3);
}
#scaleDown h2 i,
#scaleRotate h2 i,
#pushRotate h2 i,
#fallDown h2 i {
  margin-left: 0.2em;
}
#scaleDown h2 span,
#scaleRotate h2 span,
#pushRotate h2 span,
#fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}
#scaleDown a,
#scaleRotate a,
#pushRotate a,
#fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(96, 127, 184, 0.2);
}
#scaleDown a span,
#scaleRotate a span,
#pushRotate a span,
#fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}
#scaleDown a:hover,
#scaleRotate a:hover,
#pushRotate a:hover,
#fallDown a:hover,
#scaleDown a:focus,
#scaleRotate a:focus,
#pushRotate a:focus,
#fallDown a:focus {
  background: rgba(30, 35, 43, 0.2);
  box-shadow: inset 0 -1px rgba(61, 71, 85, 0);
  color: #b8b7ad;
}

body .bm-burger-button {
  position: fixed;
  width: 24px;
  height: 19px;
  left: 20px;
  top: 10px;
}
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}body{margin:0;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;margin:0.67em 0;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:bold;}dfn{font-style:italic;}hr{box-sizing:content-box;height:0;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace,serif;font-size:1em;}pre{white-space:pre-wrap;}q{quotes:"\201C" "\201D" "\2018" "\2019";}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-0.5em;}sub{bottom:-0.25em;}img{border:0;}svg:not(:root){overflow:hidden;}figure{margin:0;}fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em;}legend{border:0;padding:0;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,select{text-transform:none;}button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}button[disabled],html input[disabled]{cursor:default;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0;}input[type="search"]{-webkit-appearance:textfield;box-sizing:content-box;}input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}
