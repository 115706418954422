/*
1. Estilo General
---------------

2. INPUT TEXT
---------------

2. INPUT PASSWORD
---------------

---------------
4. Estilo Fuente
---------------
    4.1. Poppins
    4.2. Roboto
5. Tamaño Fuente
---------------
*/
@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

/***************************************************
					1. Estilo General
***************************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	border: 0;
	margin: 0;
	font-size: 100%;
	font: inherit;
	padding: 0;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	background: #000; /*background por defecto body */
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size:18px;
	line-height: 1;
}
ol, ul {
	list-style: none;
}
input[type="submit"]{
	-webkit-appearance: none;
	}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img{
	border:none
	}
/*div#cuerpo, div.footer, */
div.contenido{
	/*width:1200px;*/
	margin:0 auto;
	width:1200px;
	}
.contenido.max_360{
	max-width:360px;
}
a{
	text-decoration:none
	}
p{
	color: #fff;
}
.clr{
	clear:both
	}
@media (max-width: 1200px) {
	/*div#cuerpo, div.footer, */

	div.contenido{
		width:100%;
	}
}
@media (max-width: 767px) {
	body {
		font-size:14px;
	}
}

@media (max-width: 1199px){
	/* .form{
		margin: 50px 5%;
	} */
}
header{
	/* height: 50px; */
	  background: #13161d;
	  color: #ffffff;
}
/***************************************************
					1. HEADER
***************************************************/

.iconUsu{
	border-radius: 50%;
}
.title_header{
	text-align: center;
	width: 100%;
}
	.logo{
		height: 35px;
		width: auto;
	}
	.text_logo{
		display: inline;
		padding: 12px;
		/* width: auto; */
	}
/***************************************************
					1. Titulos
***************************************************/
h2{
	color: #ffffff;
	line-height: 20px;
	letter-spacing: 1.285714px;
	margin: 5px auto;
	text-align: center;
}
h3{
	color: #cccccc;
	line-height: 20px;
	letter-spacing: 0.5px;
}
#title_section .MuiSvgIcon-root{
	margin: -3px 0;
}
#title_section h3{
	margin-left: 10px;
}
/***************************************************
					1. CONTENIDO
***************************************************/
.mainpage{
	background: #1E232B;
	/* height: 100vh; */
	padding-top: 30px;
	padding-bottom: 60px;
	height: 100%;
	min-height: 96vh;
}
.form{
	background: #1E232B;
	box-sizing: border-box;
	/* margin: 50px auto; */
	margin: 0 auto;
	max-width: 360px;
	min-height: 100vh;
	padding: 24px 20px;
}
.section_form{
	position: relative;
	background: inherit;
}
.nav{
	background: #788ba5;
}
.inline{
	display: inline-block;
	margin: -2px 2px;
}
/**Input**/
/* .MuiOutlinedInput-input{
	border: solid 1px #3d4755;
	background: #13161d !important;
	color: #ffffff !important;
} */
.form .MuiFormControl-root{
	margin: 10px 0;
	width: 100%;
}
.form .MuiInputBase-input, .input_dark{
	background: #13161d;
	color: #ffffff;
	width: 100%;
}
.form .MuiInputLabel-outlined,.form .MuiInputLabel-outlined.Mui-focused, .input_dark label.Mui-focused, .input_dark fieldset.Mui-focused{
	color: #cccccc;
}
.form .MuiOutlinedInput-notchedOutline, .form .MuiOutlinedInput-notchedOutline .Mui-focused, .form .MuiOutlinedInput-notchedOutline:hover, .input_dark.MuiOutlinedInput-notchedOutline:hover {
	border-color: #3d4755;
}
/* .form .MuiOutlinedInput-root.Mui-focused{
	border-color: rgba(255, 255, 255, 0.23);
} */
/**Select**/
.form  .MuiSelect-icon {
	color: #3d4755;
}
/**Data Picker**/
.coloricon, .coloricon .MuiSvgIcon-root{
	color: #3d4755;
}
.Mui-disabled{
	border-color: #3d4755;
}
.form .nobackground, .nobackground{
	background-color: transparent;
	border-bottom: 1px solid #5b6a7f;
}
/**Tab**/
.OpTab .MuiBox-root{
	padding: 0;
}
.tabsfull .MuiTab-textColorInherit{
	width: 50%;
}
/**Card**/
.list_card .MuiCardHeader-action{
	border-left: solid 1px #3d4755;
	height: 110px;
}
.list_card .MuiCardHeader-action button.MuiButtonBase-root{
	margin:30px 0;
}
.list_card span{
	vertical-align: middle;
	margin-left: 5px;
}
.list_card .MuiSvgIcon-root, .sbtitulo{
	color: #5b6a7f;

}
.list_card .MuiSvgIcon-root{
	width: 20px;
	vertical-align: middle;
}
.bgazul{
	/* background-color: #1338be; */
	width:auto;
	/* width: 12px;
	height: 12px; */
	border-radius: 50%;
	display: inline-block;
	vertical-align: super;
}
.list_card .bgazul .MuiSvgIcon-root{
	color: #1338be;
}
.bgverde{
	/* background-color: #32cd32; */
	border-radius: 50%;
	width:auto;
	/* width: 12px;
	height: 12px; */
	display: inline-block;
	vertical-align: super;
}
.list_card .bgverde .MuiSvgIcon-root{
	color: #32cd32;
	;
}
.bgrojo{
	/* background-color: #f04d43; */
	border-radius: 50%;
	width:auto;
	/* width: 12px;
	height: 12px; */
	display: inline-block;
	vertical-align: super;
}
.list_card .bgrojo .MuiSvgIcon-root{
	color: #f04d43;
	;
}
body .LinkItem{
	color: inherit;
}
body .LinkItem.verlogs{
	margin: 30px 0;
	position: relative;
	display: block;
	font-weight: 500;
}
/***************************************************
					2. INPUT TEXT
***************************************************/

input{
	background: #13161d;
	border: solid 1px #3d4755;
	border-radius: 10px;
	box-sizing: border-box;
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	height: 40px;
	letter-spacing: 0.5px;
	line-height: 17px;
	margin: 5px auto;
	padding: 0 10px;
	width: 100%;
}
.label_input{
	line-height: 12px;
	margin: 6px 0;
}
.margininput{
	margin: 9px 0;
}
/* .form .MuiGrid-item{
	padding-bottom: 0px !important;
	padding-top: 0px !important;
} */
.cont-1{
	width: 100%;
}
.cont-2{
	width: 50%;
}
.cont-3{
	width: 34%;
}

/***************************************************
					3. BUTTON
***************************************************/
.boton{
	border-radius: 10px;
	cursor: pointer;
	height: 40px;
	letter-spacing: 0.5px;
	line-height: 15px;
	margin: 5px auto;
	text-align: center;
	width: 100%;
}
.full_content{
	width: 100%;
	text-transform: capitalize;
}
.MuiButtonBase-root.margin_top, .margin_top{
	margin-top: 10px;
}
/* .btn_general{
	background: #788ba5;
	color: #ffffff;
	border: solid 1px #788ba5;
}
.btn_secundario{
	background: #ffffff;
	border: solid 1px #788ba5;
	color: #5b6a7f;
} */

/**Calendar**/
.calendar .MuiIconButton-root {
	padding: 12px 0;
}
.calendar .MuiInputBase-input{
	font-size: 14px;
}
/**button more**/
.btn_more{
	display: block;
	text-align: center;
}
/***************************************************
					4. SELECT
***************************************************/
select{
	background: #13161d;
	border: solid 1px #3d4755;
	border-radius: 10px;
	color: #ffffff;
	height: 40px;
	line-height: 17px;
	letter-spacing: 0.5px;
	width: 100%;
}
/* .text_select, .MuiList-root .text_select{
	color: #ffffff;
} */
/* .marginselect{
	margin: 14px 0;
}
.selectui{
	background: #13161d;
	border: solid 1px #3d4755;
	border-radius: 10px !important;
	color: #ffffff !important;
	height: 40px;
	line-height: 17px;
	letter-spacing: 0.5px;
	width: 100%;
}
.MuiMenu-list, .MuiMenu-list{
	color: #ffffff !important;
	background: #13161d;
	/* border: solid 1px #3d4755; /
}
.MuiMenu-list li{
	border-bottom: solid 1px #3d4755;
}
.MuiMenu-list li:hover{
	background: #1e232b;
}
.MuiSelect-icon{
	color: #ffffff !important ;
} */

/***************************************************
					5. TEXTAREA
***************************************************/
textarea{
	background: #13161d;
	border: solid 1px #3d4755;
	box-sizing: border-box;
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	/* height: 81px; */
	letter-spacing: 0.5px;
	line-height: 17px;
	margin: 5px auto;
	padding: 8px 12px;
	width: 100%;
}
/***Timeline***/
.line_time{
	background: rgb(160, 178, 184) none repeat scroll 0% 0%;
	height: 65%;
	left: 16px;
	position: absolute;
	top: 40px;
	width: 2px;
}
.cont_timeline{
	font-size: 80%;
	font-weight: 300;
	margin: 0px auto;
	padding: 10px 0px;
	position: relative;
	width: 100%;
}
	.contItem{
		/* margin: 10px 0px; */
		padding-left: 40px;
		position: relative;
		text-align: left;
		/* height: 60px; */

	}
		.divider{
			border-bottom: 1px solid #788ba5;
			bottom: 0;
			height: 1px;
			left: 20px;
			position: absolute;
			width: 95%;
			z-index: 0;
		}
		.iconItem{
			background: #13161d none repeat scroll 0% 0%;
			border-radius: 50%;
			display: flex;
			/* top: 20px; */
			height: 20px;
			left: 6px;
			margin-left: 1px;
			margin-top: 6%;
			position: absolute;
			width: 20px;
			z-index: 1;
		}
			.icon_green{
				border: 2px solid #36af47;
			}
			.icon_red{
				border: 2px solid #e02020;
			}
			.icon_gris{
				border: 2px solid #788ba5;
			}
		.datos{
			position: relative;
			text-align: left;
			/* padding: 25px 0; */
			padding: 3% 0;
		}
		/* .datos .dateItem{

		} */
		.datos .descItem span{
			display: block;
			margin: 0;
		}
		.contItem:last-child .line_time, .contItem:last-child .divider{
			display: none;
		}
	/**Syle 2 date icon**/
		.contItem{
			padding-left: 50px;
		}
		.iconItemData{
			background: #13161d none repeat scroll 0% 0%;
			border-radius: 50%;
			display: flex;
			/* top: 20px; */
			width: 40px;
  			height: 40px;
			left: 0;
			/* margin-left: 1px; */
			margin-top: 6%;
			position: absolute;
			z-index: 1;
		}
			.iconItemData p{
				width: 40px;
				text-align: center;
				padding: 2px 0;
				letter-spacing: 0.7px;
			}
			.iconItemData.icon_gris p{
				color:#788ba5;
			}

		.DateIcon .datos{
			position: relative;
			text-align: left;
			/* padding: 25px 0; */
			padding: 10% 0;
		}
/* Home */
/* body .item_card {

} */
body .btn_rigth{
	float: right !important;
}
.icon_form{
	vertical-align: sub;
}
/**/
/********List Item**********/
.alignv_mid{
	vertical-align:middle;
}
.alignv_sub{
	vertical-align: sub;
}
/**/
.MuiSpeedDialAction-staticTooltipLabel, .optionspeed{
	min-width: max-content !important;
}
/***************************************************
                    6. Estilo Fuente
                        4.1. Montserrat
***************************************************/
/*Fuentes100;200;300;400;500;600;700;800;900*/
body .fnt_black{
    font-weight: 900;
}
body .fnt_extrabold{
    font-weight: 800;
}
body .fnt_bold{
    font-weight: 700;
}
body .fnt_semibold{
    font-weight: 600;
}
body .fnt_medium{
    font-weight: 500;
}
body .fnt_regular{
    font-weight: 400;
}
body .fnt_light{
    font-weight: 300;
}
body .fnt_extralight{
    font-weight: 200;
}
body .fnt_thin{
    font-weight: 100;
}
/***************************************************
					3. Separador
***************************************************/
.separador {
	border: solid 1px #979797;
	box-sizing: border-box;
	display: block;
	/* height: 1px; */
	margin:8px 20px;
	position: relative;
  }
/***************************************************
					5. Tamaño Fuente
***************************************************/
.fnt_18{
	font-size: 18px;
}
.fnt_14, .fnt_14 span{
	font-size: 14px;
}
.list_card .fnt_14 span{
	font-size: 12px;
}
.fnt_12{
	font-size: 12px;
}
.fnt_10{
	font-size: 10px;
}
/*---------------------*/


.fnt_38{
	font-size: 38px;
}
.fnt_25_1{
	font-size: 25.01px;
}
.fnt_25{
	font-size: 25px;
}
.fnt_24{
	font-size: 24px;
}

.fnt_17_9{
	font-size: 17.99px;
}
.fnt_17{
	font-size: 17px;
}
.fnt_16{
	font-size: 16px;
}
.fnt_16_1{
	font-size: 16.01px;
}
/*custom sweetalert2*/
body .bg_dark{
	background: #1e232b;
	color: #fff;
	border: 1px solid #788ba5;
}
body .btn_success{
	background-color: rgb(189, 173, 144) !important;
}
body .btn_deny{
	background-color: #788ba5 !important;
}
body .btn_cancel{
	border: 1px solid #788ba5 !important;
	background-color: #1e232b !important;
}
body .text_white{
	color: #fff !important;
}
body .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px #788ba5;
}
