.link_a{
    color: white;
}

.border_input{
	box-sizing: border-box;
    border-radius: 4px;
    height: max-content;
    background-color: #656565;
    font-size: 18px;
    color: white;
    border: none;

}

.cont_icon{
	height: 56px;
    width: 50px;
    border-radius: 4px;
	background-color: #333333;
    float: left;
    position: relative;
}

.botom_login{
	width: 100%;
}

.texto_login{
	display: block;
    margin: 3px auto;
    font-weight: 600;
    font-size: 15px;
}
.aling_text{
    text-align: right !important;
    width: 260px;	
}
.logo_login{
    margin-top: 50px;
    margin-bottom: 50px;
}
.form_login{
    margin-top: 50px;
}
.titulo_recuperar{
    color: #9F8E6E;
    text-align: center;
    font-weight: bold;
}
.contenido_popup{
    color: #fff !important;
    text-align: center;
}
.boton_cancelar{
    color: #9F8E6E !important;
    border: 2px solid #9F8E6E !important;
}
.boton_enviar{
    color: #fff !important;
    background-color: #9F8E6E !important;
}
.input_dark {
    background: #13161d;
    color: #ffffff;
}

